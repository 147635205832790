import { Col, Form, Input, Row, Select, Button } from 'antd'
import React, { useEffect } from 'react'
import { Grid } from '@material-ui/core'
import { FormattedMessage } from 'react-intl'

import makeRequest from '../../../libs/request'
import {
  showErrorMessage,
  showSuccessMessageIcon,
} from '../../../actions/notification'

const AddOwnerComponent = ({ values, handleCancel, handleSave, companyId }) => {
  const [form] = Form.useForm()
  const handleSubmit = async (value) => {
    let payload = {
      ...values,
      name: value?.name ?? undefined,
      email: value?.email ?? undefined,
      mobile_number:
        value?.mobile_number !== '' ? value?.mobile_number : undefined,
      type: value?.type ?? undefined,
      company: values?.company ?? companyId,
    }
    if (payload?._id) {
      await makeRequest('put', `icpa/owners/update/${payload?._id}`, payload)
        .then(({ data }) => {
          if (data.signal) {
            form.setFieldsValue({
              name: '',
              email: '',
              mobile_number: '',
              type: 'co-owner',
              company: companyId,
            })
          } else {
            return showErrorMessage('Update owners successfully.')
          }
        })
        .catch((err) => {
          console.log(err)
        })
    } else {
      await makeRequest('post', `icpa/owners/create`, payload)
        .then(({ data }) => {
          if (data.signal) {
            form.setFieldsValue({
              name: '',
              email: '',
              mobile_number: '',
              type: 'co-owner',
              company: companyId,
            })
          } else {
            return showErrorMessage('Add Owners successfully.')
          }
        })
        .catch((err) => {
          console.log(err)
        })
    }
    await handleSave(payload)
    return
  }

  useEffect(() => {
    form.setFieldsValue({
      name: values?.name ?? '',
      email: values?.email ?? '',
      mobile_number: values?.mobile_number ?? '',
      type: values?.type ?? 'co-owner',
      company: values?.company ?? companyId,
    })
  }, [values])

  return (
    <Form
      form={form}
      name='login'
      autoComplete='off'
      layout='vertical'
      onFinish={handleSubmit}
      requiredMark={false}
      className='form-item-customer required-value'
      initialValues={{}}
    >
      <Row gutter={24} className='mt-16'>
        <Col className='gutter-row' span={24}>
          <Form.Item
            label='Customer Name'
            name='name'
            rules={[
              {
                required: true,
                message: 'Customer Name is required!',
                whitespace: true,
              },
              {
                required: false,
                min: 5,
                message: 'Minimum length is 5 characters.',
              },
              {
                required: false,
                max: 40,
                message: 'Maximum length is 40 characters.',
              },
            ]}
          >
            <Input placeholder='Enter customer Name' />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24} className='mt-8'>
        <Col span={24}>
          <Form.Item
            label='Email for Notification'
            name='email'
            rules={[
              {
                required: true,
                message: 'Email for notification is required!',
              },
              {
                required: false,
                pattern: new RegExp(/^[a-zA-Z0-9._-]+@[a-z]+\.[a-z]{2,6}$/),
                message: 'Email for Notification invalid!',
              },
            ]}
          >
            <Input placeholder='Enter email' />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24} className='mt-8'>
        <Col span={24}>
          <Form.Item
            label='Mobile Number'
            name='mobile_number'
            rules={[
              { required: true, message: 'Mobile number is required!' },
              {
                required: false,
                pattern: new RegExp(/^(?!0+$)\d{7,13}$/),
                message: 'Mobile number invalid!',
              },
            ]}
            onKeyPress={(event) => {
              if (!/[0-9]/.test(event.key)) {
                event.preventDefault()
              }
            }}
          >
            <Input placeholder='Enter mobile number' />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24} className='mt-8'>
        <Col span={24}>
          <Form.Item
            label='Ownership'
            name='type'
            rules={[{ required: true, message: 'Ownership is required!' }]}
          >
            <Select
              placeholder='Select owner'
              optionFilterProp='label'
              options={[
                { label: 'Co-Owner', value: 'co-owner' },
                { label: 'Main Owner', value: 'main-owner' },
              ]}
            />
          </Form.Item>
        </Col>
      </Row>

      <Form.Item className='row-center mt-32'>
        <Grid container spacing={1}>
          <Grid container item xs={6}>
            <Button
              variant='secondary'
              type='button'
              className='lms-secondary-btn'
              style={{
                width: '100%',
                height: '40px',
                marginRight: 5,
              }}
              onClick={handleCancel}
            >
              <FormattedMessage id='PAGE.BUTTON.SPAN.CANCEL' />
            </Button>
          </Grid>
          <Grid container item xs={6}>
            <Button
              variant='primary'
              type='submit'
              htmlType='submit'
              className='lms-btn'
              style={{
                width: '100%',
                height: '40px',
              }}
            >
              Confirm
            </Button>
          </Grid>
        </Grid>
      </Form.Item>
    </Form>
  )
}

export default AddOwnerComponent
