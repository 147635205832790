import React, { useEffect, useState } from 'react'

import { Progress, Upload } from 'antd'
import CloseCircleFillIcon from './icons/close-circle-fill-icon'
import DownloadIcon from './icons/download-icon'
import EyeFillIcon from './icons/eye-fill-icon'
import {
  showErrorMessage,
  showSuccessMessage,
} from '../../../../actions/notification'

const checkIsPdf = (url) => {
  return url.indexOf('.pdf') !== -1
}

const getFileNamePdf = (url) => {
  if (url) {
    // return url.split("/").pop();
    let string = url.substring(url.lastIndexOf('/') + 1)
    return decodeURI(string).replace(/\+/g, ' ')
  }
}

export default function UploadFileComponent({
  company_id,
  _id,
  name,
  type,
  detail_id,
  isDeleteFile,
  isReview,
  disabled,
  setIsUpload,
  setStatus,
  isReport,
  year,
  ...props
}) {
  const [file, setFile] = useState()
  const [uploading, setUploading] = useState(false)
  const [loadingImage, setLoadingImg] = useState(false)
  const [progress, setProgress] = useState(0)
  let companyId = company_id

  const beforeUploadImage = (file) => {
    const isJpgOrPng =
      file.type === 'application/pdf' ||
      file.type ===
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
      file.type === 'application/doc' ||
      file.type === 'application/vnd.ms-excel'
    const size = file.size / 1024 / 1024
    const isLt2M = size <= 100
    if (!isJpgOrPng) {
      showErrorMessage({ title: 'You can only upload PDF/DOC file!' })
      return false
    } else if (!isLt2M) {
      showErrorMessage({
        title: `${props?.label ?? 'Logo'} size more than limit (100MB)`,
      })
      return false
    }
    return isJpgOrPng
  }

  const removeFileThumbnailItem = async (url) => {
    setProgress(0)
    if (setStatus) {
      setStatus(1)
    }
    // await deleteUrlLink({
    //   arrayKey: url,
    //   singleFile: true,
    // })
    if (isReport) {
      // await updateFileReport({
      //   payload: {
      //     _id: props?.value?._id,
      //     name: props?.value?.name,
      //     file_size: 0,
      //     file_url: '',
      //     year: props?.value?.year,
      //     status: props?.value?.status,
      //     company: companyId,
      //   },
      //   companyId,
      // })
    } else {
      if (file?._id && (file?.detail || detail_id)) {
        if (isDeleteFile) {
          // await updateFile({
          //   _id: _id,
          //   url: null,
          //   file_size: null,
          //   name: file?.name,
          //   type: file?.type,
          //   detail_id: file?.detail ?? detail_id,
          // })
        } else {
          // await deleteDocuments({
          //   detail_id: file?.detail ?? detail_id,
          //   document_id: file?._id,
          // })
        }
      }
    }
    if (setStatus) {
      setStatus(2)
      setTimeout(() => {
        setStatus(0)
      }, 1000)
    }
    props.onChange(null)
    setFile(null)
  }

  const uploadFile = async (options) => {
    const { file, onProgress } = options
    if (file && companyId) {
      setUploading(true)
      const config = {
        headers: { 'content-type': 'multipart/form-data' },
        onUploadProgress: (event) => {
          if (setStatus) {
            setStatus(1)
          }
          const percent = Math.floor((event.loaded / event.total) * 100)
          setProgress(percent)
          setIsUpload(true)
          if (percent === 100) {
            setTimeout(() => setProgress(0), 1000)
          }
          onProgress({ percent: (event.loaded / event.total) * 100 })
        },
      }
      const fileResponse = null
      // const fileResponse = await updateFileCompany({
      //   file: file,
      //   id: companyId,
      //   config,
      //   name,
      //   type,
      //   detail_id,
      // })

      setLoadingImg(false)
      if (setStatus) {
        setStatus(2)
        setTimeout(() => {
          setStatus(0)
        }, 1000)
      }
      if (!fileResponse?.errorCode) {
        showSuccessMessage({ title: 'Update file successfully.' })
        let isPdf = checkIsPdf(fileResponse?.url)
        let file_name = getFileNamePdf(fileResponse?.ur)
        let fileData = {
          url: fileResponse?.url,
          file_size: fileResponse?.file_size,
          file_name: file_name,
          isPdf: isPdf,
        }
        if (isReport) {
          // await updateFileReport({
          //   payload: {
          //     _id: props?.value?._id,
          //     name: props?.value?.name,
          //     file_size: fileData?.file_size,
          //     file_url: fileData?.url,
          //     year: props?.value?.year,
          //     status: props?.value?.status,
          //     company: companyId,
          //   },
          //   companyId,
          // })
        } else {
          // await updateFile({
          //   _id: _id,
          //   url: fileData?.url,
          //   file_size: fileData?.file_size,
          //   name,
          //   type,
          //   detail_id,
          // })
        }
        setIsUpload(false)
        setProgress(0)
        setFile(fileData)
        props.onChange(fileData)
      } else {
        setIsUpload(false)
        showErrorMessage({ title: fileResponse?.errorCode })
        setProgress(0)
        setFile(null)
        props.onChange(null)
      }
      setUploading(false)
    }
  }

  useEffect(() => {
    if (props?.value && props?.value?.url) {
      let isPdf = checkIsPdf(props?.value?.url)
      let file_name = getFileNamePdf(props?.value?.url)
      setFile({
        ...props?.value,
        file_name,
        isPdf: isPdf,
      })
      setProgress(0)
    } else {
      setProgress(0)
      setFile(null)
    }
  }, [props?.value])
  return (
    <div
      style={{
        minHeight: isReview ? '69px' : '69px',
      }}
    >
      {(progress > 0 || file) && (
        <div
          style={{
            borderRadius: '8px',
            minWidth: '100%',
            minHeight: isReview ? '69px' : '69px',
            background: '#F4F7FE',
            padding: '7px 16px',
            gap: '19.29px',
          }}
        >
          {file && (
            <div
              style={{
                minWidth: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                padding: '5px 0px',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  gap: '19.29px',
                  overflow: 'hidden',
                }}
              >
                <img
                  src={file?.isPdf ? '/images/pdf.png' : '/images/doc.svg'}
                  alt='images'
                  style={{
                    width: '40px',
                    height: '40px',
                  }}
                />
                <div>
                  <div
                    className='f-semibold_20 black-color limit-text-1'
                    style={{
                      whiteSpace: 'normal',
                      wordBreak: 'break-word',
                      overflow: 'hidden',
                    }}
                    title={
                      file?.file_name
                        ? file?.file_name
                        : `Upload ${props?.label}`
                    }
                  >
                    {file?.file_name
                      ? file?.file_name
                      : `Upload ${props?.label}`}
                  </div>
                  {isReview ? (
                    <div className='d-flex mt-5'>
                      {year && (
                        <span
                          className='mr-8'
                          style={{
                            fontSize: '14px',
                            color: '#818DAB',
                          }}
                        >
                          {year}
                        </span>
                      )}
                      <a
                        href={file.url}
                        target='_blank'
                        rel='noopener noreferrer'
                        download
                      >
                        <div className='cursor-pointer row-center f-regular_18 primary-color mr-10'>
                          <div className='row-center mr-8'>
                            <DownloadIcon fill={'#338FEB'} />
                          </div>
                          <span>Download</span>
                        </div>
                      </a>

                      <a
                        className='upload-list-item-thumbnail'
                        href={`http://docs.google.com/gview?url=${file.url}&embedded=true`}
                        target='_blank'
                        rel='noopener noreferrer'
                      >
                        <div className='cursor-pointer row-center f-regular_18 primary-color'>
                          <div className='row-center mr-8'>
                            <EyeFillIcon fill={'#338FEB'} />
                          </div>
                          <span>Preview</span>
                        </div>
                      </a>
                    </div>
                  ) : (
                    <div className='f-regular_18 color-grey-600 mt-5'>
                      {file?.file_size ? file?.file_size.toFixed(2) : 0}MB
                    </div>
                  )}
                </div>
              </div>
              {!disabled && (
                <div
                  className='cursor-pointer rowy-center'
                  onClick={() => removeFileThumbnailItem(file?.url)}
                >
                  <CloseCircleFillIcon />
                </div>
              )}
            </div>
          )}
          {progress > 0 && !file ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-around',
                alignItems: 'center',
              }}
              className='upload-custom'
            >
              <div className='width-full mr-8'>
                <div className='f-semibold_20 black-color'>Uploading...</div>
                <Progress
                  percent={progress}
                  strokeColor={'#338FEB'}
                  showInfo={false}
                />
              </div>
            </div>
          ) : null}
        </div>
      )}

      <Upload
        name='file'
        beforeUpload={beforeUploadImage}
        customRequest={uploadFile}
        showUploadList={false}
        multiple={false}
        disabled={loadingImage || disabled}
        className='upload-file-custom'
        status={'uploading'}
      >
        {!file && !uploading && (
          <div
            className='cursor-pointer'
            style={{
              borderRadius: '8px',
              display: 'flex',
              alignItems: 'center',
              minWidth: '100%',
              minHeight: isReview ? '69px' : '69px',
              background: '#F4F7FE',
              padding: '7px 16px',
              gap: '19.29px',
            }}
          >
            <img
              src='/images/upload-cloud-fill.svg'
              alt='images'
              style={{
                width: '40px',
                height: '40px',
              }}
            />
            <div>
              <div className='f-regular_20 color-grey-500'>
                Upload {props?.label}
              </div>
              <div className='f-regular_10 color-grey-500'>
                Maximum size: 100 MB | File format: PDF, Doc{' '}
              </div>
            </div>
          </div>
        )}
      </Upload>
    </div>
  )
}
