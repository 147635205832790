/* eslint-disable no-restricted-imports */
import React, { useState } from 'react'
import { useEffect } from 'react'
import { Card } from 'react-bootstrap'

import UploadFileComponent from '../../../common/antd/component/upload/UploadFileComponent'
import makeRequest from '../../../libs/request'
import { showErrorMessage } from '../../../actions/notification'
import { Col, Row, Tag } from 'antd'
import moment from 'moment'

export default function YourPublicReportComponent(props) {
  const { value = {}, show = false, tab } = props
  const [documentList, setDocumentList] = useState([])
  const getCompanyInfo = async () => {
    makeRequest('get', `icpa/reports/get-all/${value?._id}`)
      .then(({ data }) => {
        if (data.signal) {
          let itemList = data.data
          if (itemList?.length > 0) {
            let list = itemList.map((i) => {
              return {
                ...i,
                url: i.file_url,
              }
            })
            setDocumentList(list)
          } else {
            setDocumentList([])
          }
        } else {
          return showErrorMessage(data.message)
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  useEffect(() => {
    if (value?._id && show) {
      getCompanyInfo()
    }
  }, [value, show, tab])

  let renderStatus = (status) => {
    if (status === 'published') {
      return (
        <Tag bordered={false} className='ml-8 tag-marking-progress-2 mr-0'>
          Published
        </Tag>
      )
    } else if (status === 'unpublished') {
      return (
        <Tag bordered={false} className='ml-8 tag-draft mr-0'>
          Unpublished
        </Tag>
      )
    }
  }
  return (
    <>
      {documentList?.length > 0 && (
        <Card className='mt-24'>
          <Card.Body>
            <Row gutter={24} className='mt-8'>
              {documentList.map((i, index) => {
                return (
                  <Col
                    key={index}
                    xs={24}
                    sm={24}
                    md={12}
                    lg={12}
                    xl={12}
                    xxl={12}
                  >
                    <div>
                      <div className=' d-flex align-items-center justify-content-between mt-8'>
                        <div
                          style={{
                            fontSize: '14px',
                            fontWeight: '600',
                            color: '#1F263E',
                          }}
                          className='mb-8 rowy-center '
                        >
                          Document
                        </div>
                        {renderStatus(i.status)}
                      </div>
                      <UploadFileComponent
                        value={i}
                        label={`License`}
                        type={'license'}
                        isDeleteFile={true}
                        disabled={true}
                        isReview={true}
                        year={
                          i?.year ? moment(i?.year).format('DD/MM/YYYY') : ''
                        }
                      />
                    </div>
                  </Col>
                )
              })}
            </Row>
          </Card.Body>
        </Card>
      )}
    </>
  )
}
