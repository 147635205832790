import checkPermission from "../../app/libs/permission";

export default {
	header: {
		self: {},
		items: [],
	},
	aside: {
		self: {},
		items: [
			{
				title: 'Review Test',
				root: true,
				image: '/images/menu-icon/icon-18.svg',
				page: 'dashboard',
				permission: 'marker',
				pathname: [
					{
						content: 'Detail',
						path: '/detail-progress/:id',
					},
				],
			},
			{
				title: 'Dashboard',
				root: true,
				page: 'dashboard',
				translate: 'MENU.DASHBOARD',
				permission: 'dashboard.view',
				bullet: 'dot',
				pathname: [
					{
						content: 'Dashboard',
						path: '/dashboard',
					},
					{
						content: 'Inquiry Management',
						path: '/inquiry/list',
					},
					{
						content: 'Inquiry Detail',
						path: '/inquiry/view/:id',
					},
				],
				image: '/images/menu-icon/icon-18.svg',
			},
			{
				title: 'Course Categories',
				root: true,
				image: '/images/menu-icon/icon-17.svg',
				bullet: 'dot',
				permission: ['property.view', 'industry.view', 'level.view'],
				submenu: [
					{
						title: 'Industry',
						page: 'industry/list',
						permission: 'industry.view',
						pathname: [
							{
								content: 'Industry',
								path: '/industry/list',
							},
						],
					},
					{
						title: 'Level',
						page: 'level/list',
						permission: 'level.view',
						pathname: [
							{
								content: 'Level',
								path: '/level/list',
							},
						],
					},
				],
			},
			{
				title: 'Course Management',
				root: true,
				image: '/images/menu-icon/icon-16.svg',
				permission: [
					'course',
					'course_online.view',
					'course_onsite.view',
					'course_analytics.view',
				],
				bullet: 'dot',
				submenu: [
					{
						title: 'Online Course',
						page: 'courses/list-online',
						permission: 'course_online.view',
						pathname: [
							{
								content: checkPermission('course_onsite.update') ? 'Edit Course' : `Detail Course`,
								path: '/course/edit/:id',
							},
							{
								content: `Online Course`,
								path: '/courses/list-online',
							},
							{
								content: `Create Course`,
								path: '/course/create',
							},
							{
								content: 'Attendee',
								path: '/course/attended/:id',
							},
							{
								content: 'Review Exam',
								path: '/course/:course/preview-exam/:exam/version/:version'
							},
							{
								content: 'Detail Response Exam',
								path: '/course/:id/user-data/:user_id/item/:progress_id'
							},
							{
								content: 'Response Exam',
								path: '/course/:id/user/:user_id'
							},
							{
								content: 'Review Exam',
								path: '/course/view/:id/preview-exam/:exam_id/version/:version_id'
							},
							{
								content: 'Review Exam',
								path: '/course/view/:id/analytic-exam/:exam_id'
							},
						],
					},
					{
						title: 'Live Training',
						page: 'courses/list-onsite',
						permission: 'course_onsite.view',
						pathname: [
							{
								content: checkPermission('course_onsite.update') ? 'Edit Course' : `Detail Course`,
								path: '/course/edit/:id',
							},
							{
								content: `Live Training`,
								path: '/courses/list-onsite',
							},
							{
								content: `Create Course`,
								path: '/course/create',
							},
						],
					},
					{
						title: 'Course Analytics',
						page: 'course/analytics',
						permission: 'course_analytics.view',
						pathname: [
							{
								content: 'Course Analytics',
								path: '/course/analytics',
							}
						],
					},
				],
			},
			{
				title: 'Question Bank',
				root: true,
				// icon: "flaticon-open-box",
				page: 'question-bank/list',
				permission: 'question_bank.view',
				image: '/images/menu-icon/icon-15.svg',
				pathname: [
					{
						content: 'Question Bank',
						path: '/question-bank/list',
						breadcrumb: [
							{
								title: 'Question Bank',
								link: '',
							}
						],
					},
					{
						content: 'Create Question',
						path: '/question-bank/create',
						breadcrumb: [
							{
								title: 'List Question',
								link: '/question-bank/list',
							},
							{
								title: 'Create Question',
								link: '',
							},
						],
					},
					{
						content: checkPermission('question_bank.update')
							? `Edit Question`
							: `View Question`,
						path: '/question-bank/edit/:id',
						breadcrumb: [
							{
								title: 'List Question',
								link: '/question-bank/list',
							},
							{
								title: checkPermission('question_bank.update')
									? `Edit Question`
									: `View Question`,
								link: '',
							},
						],
					},
				],
			},
			{
				title: 'Course Builder',
				root: true,
				page: 'course-builder/list',
				permission: 'course_builder.view',
				image: '/images/menu-icon/icon-14.svg',
				pathname: [
					{
						content: 'Course Builder',
						path: '/course-builder/list',
					},
					{
						content: 'Course Builder',
						path: '/course-builder/create',
					},
					{
						content: 'Course Builder',
						path: '/course-builder/edit/:id',
					},
					{
						content: 'Preview',
						path: '/course-builder/preview/:id',
					},
				],
			},
			{
				title: 'Quiz Management',
				root: true,
				page: 'quiz/list',
				permission: 'exam.view',
				image: '/images/menu-icon/icon-13.svg',
				pathname: [
					{
						content: 'Quiz Management',
						path: '/quiz/list',
					},
					{
						content: 'Review Quiz',
						path: '/quiz/preview/:id',
					},
					{
						content: 'Create Quiz',
						path: '/quiz/create',
					},
					{
						content: 'Edit Quiz',
						path: '/quiz/edit/:id',
					},
				],
			},
			{
				title: 'Program Management',
				root: true,
				page: 'training/list',
				bullet: 'dot',
				permission: 'training.view',
				image: '/images/menu-icon/icon-12.svg',
				pathname: [
					{
						content: 'Program Management',
						path: '/training/list',
					},
					{
						content: 'Create Event',
						path: '/training/create',
					},
					{
						content: checkPermission('training.update') ? `Edit Event` : `Detail Event`,
						path: '/training/edit/:id',
					}
				],
			},
			{
				title: 'User Management',
				root: true,
				// icon: "flaticon-users",
				image: '/images/menu-icon/icon-11.svg',
				bullet: 'dot',
				permission: [
					'user_mngt',
					'role.view',
					'admin.view',
					'user.view',
					'marker.view',
				],
				submenu: [
					{
						title: 'Admin',
						page: 'admin/list',
						permission: 'admin.view',
						pathname: [
							{
								content: 'Admin Management',
								path: '/admin/list',
								breadcrumb: [
									{
										title: 'Admin Management',
										link: '',
									}
								],
							},
							{
								content: 'Create Admin',
								path: '/admin/create',
								breadcrumb: [
									{
										title: 'Admin Management',
										link: '/admin/list',
									},
									{
										title: 'Create Admin',
										link: '',
									},
								],
							},
							{
								content: 'Edit Admin',
								path: '/admin/edit/:id',
								breadcrumb: [
									{
										title: 'Admin Management',
										link: '/admin/list',
									},
									{
										title: "Edit Admin",
										link: '',
									},
								],
							},
						],
					},
					{
						title: 'Users',
						page: 'users/list',
						permission: 'user.view',
						pathname: [
							{
								content: 'Users',
								path: '/users/list',
								breadcrumb: [
									{
										title: 'Users',
										link: '',
									}
								],
							},
							{
								content: 'Create User',
								path: '/users/create',
								breadcrumb: [
									{
										title: 'Users',
										link: '/users/list',
									},
									{
										title: 'Create User',
										link: '',
									},
								],
							},
							{
								content: 'Edit User',
								path: '/users/edit/:id',
								breadcrumb: [
									{
										title: 'Users',
										link: '/users/list',
									},
									{
										title: "Edit User",
										link: '',
									},
								],
							},
						],
					},
					{
						title: 'Role & Permission',
						page: 'role/list',
						permission: 'role.view',
						pathname: [
							{
								content: 'Role & Permissions Management',
								path: '/role/list',
								breadcrumb: [
									{
										title: 'Role & Permissions Management',
										link: '',
									}
								],
							},
							{
								content: 'Create Role',
								path: '/role/create',
								breadcrumb: [
									{
										title: 'List Role',
										link: '/role/list',
									},
									{
										title: 'Create Role',
										link: '',
									},
								],
							},
							{
								content: 'Edit Role',
								path: '/role/edit/:id',
								breadcrumb: [
									{
										title: 'List Role',
										link: '/role/list',
									},
									{
										title: "Edit Role",
										link: '',
									},
								],
							},
						],
					},
					{
						title: 'Markers',
						page: 'marker/list',
						permission: 'marker.view',
						pathname: [
							{
								content: 'Marker Management',
								path: '/marker/list',
								breadcrumb: [
									{
										title: 'Marker Management',
										link: '',
									}
								],
							},
							{
								content: 'Create Marker',
								path: '/marker/create',
								breadcrumb: [
									{
										title: 'Marker Management',
										link: '/marker/list',
									},
									{
										title: 'Create Marker',
										link: '',
									},
								],
							},
							{
								content: 'Edit Marker',
								path: '/marker/edit/:id',
								breadcrumb: [
									{
										title: 'Marker Management',
										link: '/marker/list',
									},
									{
										title: "Edit Marker",
										link: '',
									},
								],
							},
						],
					},
					{
						title: 'Agent',
						root: true,
						page: 'agent/list',
						permission: 'agent.view',
						pathname: [
							{
								content: 'Agent Management',
								path: '/agent/list',
								breadcrumb: [
									{
										title: 'Agent Management',
										link: '',
									}
								],
							},
							{
								content: 'Invite Agent',
								path: '/agent/invite',
								breadcrumb: [
									{
										title: 'Agent Management',
										link: '/agent/list',
									},
									{
										title: 'Invite Agent',
										link: '',
									},
								],
							},
							{
								content: 'Edit Agent',
								path: '/agent/edit/:id',
								breadcrumb: [
									{
										title: 'Agent Management',
										link: '/agent/list',
									},
									{
										title: "Edit Agent",
										link: '',
									},
								],
							},
						],
					},
				],
			},
			{
				title: 'Subscription',
				root: true,
				// icon: "flaticon-list",
				image: '/images/menu-icon/icon-10.svg',
				bullet: 'dot',
				permission: [
					'subscripton',
					'plan.view',
					'storage.view',
					'feature.view',
				],
				submenu: [
					{
						title: 'Subscription Plan',
						page: 'plan/list',
						permission: 'plan.view',
						pathname: [
							{
								content: 'Subscription Plan',
								path: '/plan/list',
								breadcrumb: [
									{
										title: 'Subscription Plan',
										link: '',
									}
								],
							},
							{
								content: 'Create Plan',
								path: '/plan/create',
								breadcrumb: [
									{
										title: 'Subscription Plan',
										link: '/plan/list',
									},
									{
										title: 'Create Plan',
										link: '',
									},
								],
							},
							{
								content: 'Edit Plan',
								path: '/plan/edit/:id',
								breadcrumb: [
									{
										title: 'Subscription Plan',
										link: '/plan/list',
									},
									{
										title: "Edit Plan",
										link: '',
									},
								],
							},
						],
					},
					{
						title: 'Storage Management',
						page: 'storage/list',
						permission: 'storage.view',
						pathname: [
							{
								content: 'Storage Management',
								path: '/storage/list',
								breadcrumb: [
									{
										title: 'Storage Management',
										link: '',
									}
								],
							},
							{
								content: 'Create Storage',
								path: '/storage/create',
								breadcrumb: [
									{
										title: 'Storage Management',
										link: '/storage/list',
									},
									{
										title: 'Create Plan',
										link: '',
									},
								],
							},
							{
								content: 'Edit Storage',
								path: '/storage/edit/:id',
								breadcrumb: [
									{
										title: 'Storage Management',
										link: '/storage/list',
									},
									{
										title: "Edit Storage",
										link: '',
									},
								],
							},
						],
					},
					{
						title: 'Feature Plan',
						page: 'plan/feature',
						permission: 'feature.view',
						pathname: [
							{
								content: 'Feature Plan',
								path: '/plan/feature',
								breadcrumb: [
									{
										title: 'Feature Plan',
										link: '',
									}
								],
							}
						],
					},
				],
			},
			{
				title: 'CE Management',
				root: true,
				// icon: "fa fa-user-friends",
				image: '/images/menu-icon/icon-9.svg',
				page: 'corporate/list',
				permission: 'ce.view',
				badge: {
					type: 'kt-badge--md kt-badge--rounded ce-request',
					value: 0,
				},
				pathname: [
					{
						content: 'Competency Entity Management',
						path: '/corporate/list',
					},
					{
						content: 'Create Competency Entity',
						path: '/corporate/create',
					},
					{
						content: 'Edit Competency Entity',
						path: '/corporate-tab/edit/:id',
					},
					{
						content: 'Department Listing',
						path: '/department-tab/edit/:id',
					},
					{
						content: 'Course Listing',
						path: '/corporate-course-tab/edit/:id',
					},
					{
						content: 'User Listing',
						path: '/corporate-user-tab/edit/:id',
					},
					{
						content: 'Create User',
						path: '/corporate-user-tab/create/:id',
					},
					{
						content: 'Edit User',
						path: '/corporate-user-row-tab/edit/:corporate_id/user/:id',
					},
					{
						content: 'Subscription Information',
						path: '/corporate-payment-tab/edit/:id',
					},
					{
						content: 'Storage Details',
						path: '/corporate-storage/:id',
					},
					{
						content: 'Billing Activity',
						path: '/corporate-billing/:id',
					},
					{
						content: 'Competency Entity Detail',
						path: '/corporate-tab/detail/:id',
					},

				],
			},
			{
				title: 'Commercial Partners',
				root: true,
				// icon: "flaticon-interface-7",
				page: 'commercial-partners',
				permission: 'commercial.view',
				image: '/images/menu-icon/icon-8.svg',
				pathname: [
					{
						content: 'Commercial Partners',
						path: '/commercial-partners',
						breadcrumb: [
							{
								title: 'Commercial Partners',
								link: '',
							}
						],
					}
				],
			},
			{
				title: 'TSE Management',
				root: true,
				// icon: "flaticon-customer",
				image: '/images/menu-icon/icon-7.svg',
				page: 'certification-body/list',
				permission: 'tse.view',
				badge: {
					type: 'kt-badge--md kt-badge--rounded cb-request',
					value: 0,
				},
				pathname: [
					{
						content: 'TSE Management',
						path: '/certification-body/list',
						breadcrumb: [
							{
								title: 'TSE Management',
								link: '',
							}
						],
					},
					{
						content: 'Training Sales Entity Detail',
						path: '/certification-tab/detail/:id',
						breadcrumb: [
							{
								title: 'Training Sales Entity Detail',
								link: '',
							}
						],
					},
					{
						content: 'Create TSE',
						path: '/certification-body/create',
						breadcrumb: [
							{
								title: 'TSE Management',
								link: '/certification-body/list',
							},
							{
								title: "Create TSE",
								link: '',
							},
						],
					},
					{
						content: 'Edit TSE',
						path: '/certification-body/edit/:id',
						breadcrumb: [
							{
								title: 'TSE Management',
								link: '/certification-body/list',
							},
							{
								title: "Edit TSE",
								link: '',
							},
						],
					},
					{
						content: checkPermission('tse.update')
							? `Edit Training Sales Entity`
							: `View Training Sales Entity`,
						path: '/certification-tab/edit/:id',
					},
					{
						content: `Competency Entity`,
						path: '/certification-tab/competency-entity/:id',
					},
					{
						content: `Competency Entity Detail`,
						path: '/certification-tab/ce/:cb_id/detail/:id',
					},
					{
						content: `User Listing`,
						path: '/certification-tab/ce/:cb_id/users/:id',
					},
					{
						content: `Detail User`,
						path: '/certification-tab/ce-users/:cb_id/ce/:ce_id/detail/:id',
					},
					{
						content: `Course Listing`,
						path: '/certification-course-tab/edit/:id',
					},
					{
						content: `User Listing`,
						path: '/certification-admin-tab/edit/:id',
					},
					{
						content: `User Listing`,
						path: '/certification-user-tab/edit/:id',
					},
					{
						content: `Subscription Information`,
						path: '/certification-payment-tab/edit/:id',
					},
					{
						content: `Create Admin`,
						path: '/certification-admin-tab/create/:id',
					},
					{
						content: `Edit Admin`,
						path: '/certification-admin-row/edit/:cb_id/user/:id',
					},
					{
						content: `Detail User`,
						path: '/certification-user-row/detail/:cb_id/user/:id',
					},
					{
						content: `Transfer Log`,
						path: '/transfer-log/:id',
					},
					{
						content: `Storage Details`,
						path: '/certification-storage/:id',
					},
					{
						content: `Billing Activity`,
						path: '/certification-billing/:id',
					},
				],
			},
			{
				title: 'ICPA Management',
				root: true,
				// icon: "flaticon-customer",
				image: '/images/menu-icon/icon-20.svg',
				page: 'icpa/list',
				permission: 'tse.view',
				badge: {
					type: 'kt-badge--md kt-badge--rounded cb-request',
					value: 0,
				},
				pathname: [
					{
						content: 'ICPA Management',
						path: '/icpa/list',
						breadcrumb: [
							{
								title: 'ICPA Management',
								link: '',
							}
						],
					},
					{
						content: 'ICPA Edit',
						path: '/icpa/edit/:id',
					},
					{
						content: 'ICPA Edit',
						path: '/icpa/edit/:id',
					},
				],
			},
			{
				title: 'Sale Management',
				root: true,
				// icon: "flaticon-cart",
				image: '/images/menu-icon/icon-6.svg',
				bullet: 'dot',
				permission: ['sale', 'promotion.view', 'order.view', 'revenue.view'],
				submenu: [
					{
						title: 'Promotion Management',
						page: 'promotion/list',
						permission: 'promotion.view',
						pathname: [
							{
								content: 'Promotion Management',
								path: '/promotion/list',
								breadcrumb: [
									{
										title: 'Promotion Management',
										link: '',
									}
								],
							},
							{
								content: 'Create Promotion',
								path: '/promotion/create',
								breadcrumb: [
									{
										title: 'Promotion Management',
										link: '/promotion/list',
									},
									{
										title: "Create Promotion",
										link: '',
									},
								],
							},
							{
								content: 'Edit Promotion',
								path: '/promotion/edit/:id',
								breadcrumb: [
									{
										title: 'Promotion Management',
										link: '/promotion/list',
									},
									{
										title: "Edit Promotion",
										link: '',
									},
								],
							},
						],
					},
					{
						title: 'Order Management',
						page: 'order/list',
						permission: 'order.view',
						pathname: [
							{
								content: 'Order Management',
								path: '/order/list',
								breadcrumb: [
									{
										title: 'Order Management',
										link: '',
									}
								],
							},
							{
								content: 'Order Details',
								path: '/order/view/:id',
								breadcrumb: [
									{
										title: 'Order Management',
										link: '/order/list',
									},
									{
										title: "Order Details",
										link: '',
									},
								],
							}
						],
					},
					{
						title: 'Company Revenue',
						page: 'company-revenue',
						permission: 'revenue.view',
						pathname: [
							{
								content: 'Company Revenue',
								path: '/company-revenue',
								breadcrumb: [
									{
										title: 'Company Revenue',
										link: '',
									}
								],
							},
						],
					},
				],
			},
			// {
			// 	title: 'Inquiry Management',
			// 	root: true,
			// 	// icon: "flaticon-paper-plane",
			// 	image: '/images/menu-icon/icon-5.svg',
			// 	page: 'inquiry/list',
			// 	badge: {
			// 		type: 'kt-badge--md kt-badge--rounded inquiryStatusNew',
			// 		value: 0,
			// 	},
			// 	permission: 'inquiry.view',
			// },
			{
				title: 'Mass Com',
				root: true,
				// icon: "flaticon-email",
				image: '/images/menu-icon/icon-4.svg',
				page: 'mass-comm/list',
				permission: 'mass_com.view',
				pathname: [
					{
						content: 'Mass Com',
						path: '/mass-comm/list',
					},
					{
						content: 'View Mass Email',
						path: '/mass-comm/view',
					},
					{
						content: 'New Mass Email',
						path: '/mass-comm/create',
					},
					{
						content: 'Edit Mass Email',
						path: '/mass-comm/edit/:id',
					},
					{
						content: 'Use Mass Email',
						path: '/mass-comm/use/:id',
					},
				],
			},
			{
				title: 'Report',
				root: true,
				// icon: "flaticon-doc",
				image: '/images/menu-icon/icon-3.svg',
				page: 'report-overview',
				permission: 'report.view',
				pathname: [
					{
						content: 'Report',
						path: '/report-overview',
					},
				],
			},
			{
				title: 'Sales Report',
				root: true,
				image: '/images/menu-icon/icon-2.svg',
				page: 'sales-report',
				permission: 'sale_report.view',
				pathname: [
					{
						content: 'Sales Report',
						path: '/sales-report',
					},
				],
			},
			{
				title: 'Setting',
				root: true,
				image: '/images/menu-icon/icon-1.svg',
				page: 'setting',
				permission: 'setting.view',
				pathname: [
					{
						content: 'Setting',
						path: '/setting',
					}
				],
			},
			{
				title: 'Referral Code',
				root: true,
				image: '/images/menu-icon/icon.svg',
				page: 'referral/list',
				permission: 'referral_code.view',
				pathname: [
					{
						content: 'Referral Code',
						path: '/referral/list',
					}
				],
			},
			// {
			// 	title: "Advertisement",
			// 	root: true,
			// 	icon: "flaticon-star",
			// 	page: "ads/list",
			// 	permission: "adv.view",
			// },
		],
	},
}
