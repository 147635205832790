/* eslint-disable no-restricted-imports */
import { Button, Col, Form, Input, Modal, Row, Select } from 'antd'
import React, { useEffect } from 'react'
import 'react-phone-number-input/style.css'
import { useState } from 'react'
import { COUNTRY_LIST } from '../../../libs/country'
import {
  Table,
  TableBody,
  TableHead,
  TablePagination,
  TableRow,
  TableCell,
} from '@material-ui/core'
import { Card } from 'react-bootstrap'
import UploadComponent from '../../../common/antd/component/upload/UploadComponent'
import makeRequest from '../../../libs/request'
import {
  showErrorMessage,
  showSuccessMessageIcon,
} from '../../../actions/notification'
import AddOwnerComponent from './AddOwnerComponent'

export default function YourCompanyComponent(props) {
  const { value = {}, id, show = false } = props
  const [form] = Form.useForm()
  const [page, setPage] = useState(0)
  const [size, setSize] = React.useState(10)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [totalRows, setTotalRows] = useState(0)
  const [rows, setRow] = useState([])
  const [open, setOpen] = useState(false)
  const [deleteCompany, setDeleteCompany] = useState(false)
  const [remove, setRemove] = useState(false)
  const [editData, setEditData] = useState()
  let [dataAdd, setData] = useState({})
  const [loading, setLoading] = useState({})

  const onSetLoading = (key, value) => {
    setLoading({
      ...loading,
      [key]: value,
    })
  }

  const handleSubmit = (item) => {
    let payload = {
      _id: id,
      logo: item?.logo,
      company_name: item?.company_name,
      country: item?.country,
      mobile_number: item?.mobile_number,
      postal_code: item?.postal_code,
      main_address: item?.main_address,
      business_website: item?.business_website,
      owners: rows,
    }
    if (payload?._id) {
      onSetLoading('loadingPage', true)
      makeRequest('put', `icpa/companies/update-info/${id}`, {
        ...payload,
      })
        .then(({ data }) => {
          if (data.signal) {
            onSetLoading('loadingPage', false)
            showSuccessMessageIcon('Update successfully')
          } else {
            onSetLoading('loadingPage', false)
            return showErrorMessage(data.message)
          }
        })
        .catch((err) => {
          console.log('++++++++++++++++', err)
        })
      return
    }
    return
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const getData = () => {
    makeRequest('get', `icpa/companies/get-one/${id}`)
      .then(({ data }) => {
        if (data.signal) {
          form.setFieldsValue(data.data)
          setData(data.data)
        } else {
          return showErrorMessage(data.message)
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
  const getAllOwners = async () => {
    await makeRequest('get', `icpa/owners/get-all/${id}`, {
      page,
      limit: size,
    })
      .then(({ data }) => {
        if (data.signal) {
          let owners = data.data?.items?.map((i) => {
            return {
              company: i.company,
              createdAt: i.createdAt,
              email: i.email,
              mobile_number: i.mobile_number,
              name: i.name,
              status: i.status,
              type: i.type,
              updatedAt: i.updatedAt,
              _id: i._id,
            }
          })
          setRow(owners ?? [])
          setTotalRows(data.data.total)
        } else {
          return showErrorMessage(data.message)
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  useEffect(() => {
    if (show) {
      getData()
      getAllOwners()
    }
  }, [value, show])

  return (
    <>
      <Card className='mt-24'>
        <Card.Body>
          <Card.Title className='d-flex justify-content-between'>
            <div
              className='f-semibold_24'
              style={{ fontWeight: 'bold', minWidth: '223px' }}
            >
              Your Company
            </div>
            <div className='f-regular_18'>
              Impt* Dear Admin(s), This is the page of registered Client info &
              main user's email to access their account. Should client forget
              their email or change owner, we can revise the info and input NEW
              "email for notification", upon request. Then, they can go to
              "Forget Password" page to set NEW password with OTP thru the new
              email, that we keyed in.
            </div>
          </Card.Title>
          <Form
            form={form}
            name='login'
            autoComplete='off'
            style={{ minWidth: '840px' }}
            layout='vertical'
            onFinish={handleSubmit}
            requiredMark={false}
            className='form-item-customer'
            initialValues={{
              ...dataAdd,
            }}
          >
            <Form.Item label='' name='logo' className='mt-16'>
              <UploadComponent
                label='Logo'
                placeholder='Acceptable formats: JPEG, PNG. Max size: 5MB'
                onChange={(value) => form.setFieldValue('logo', value)}
              />
            </Form.Item>
            <Row gutter={24} className='mt-16'>
              <Col className='gutter-row' span={12}>
                <Form.Item
                  label='Company Name'
                  name='company_name'
                  rules={[
                    { required: true, message: 'Company name is required!' },
                  ]}
                  className='required-value'
                >
                  <Input placeholder='Enter company name' />
                </Form.Item>
              </Col>
              <Col className='gutter-row' span={12}>
                <Form.Item
                  label='Country'
                  name='country'
                  rules={[{ required: true, message: 'Country is required!' }]}
                  className='required-value'
                >
                  <Select
                    placeholder='Select country'
                    optionFilterProp='label'
                    options={COUNTRY_LIST}
                    showSearch
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24} className='mt-8'>
              <Col span={12}>
                <Form.Item label='Main Address' name='main_address'>
                  <Input placeholder='Enter address' />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label='Postal Code' name='postal_code'>
                  <Input placeholder='Enter postal code' />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24} className='mt-8'>
              <Col span={12}>
                <Form.Item
                  label='Office Phone Number'
                  name='mobile_number'
                  rules={[
                    {
                      required: false,
                      pattern: new RegExp(/^[0-9]{7,13}$/),
                      message: 'Office phone number invalid!',
                    },
                  ]}
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault()
                    }
                  }}
                >
                  <Input placeholder='Enter phone number' />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label='Business Website'
                  name='business_website'
                  rules={[
                    {
                      required: false,
                      pattern: new RegExp(
                        /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi
                      ),
                      message: 'Business website invalid!',
                    },
                  ]}
                >
                  <Input placeholder='Enter website' />
                </Form.Item>
              </Col>
            </Row>
            <div className='mt-32 mb-8 d-flex align-items-center justify-content-between'>
              <div className='f-semibold_36 black-color'>
                List of Owner(s) ({rows.length}/6)
              </div>
              {rows.length < 6 && (
                <Button
                  className='lms-btn btn btn-bold btn-icon-h text-light'
                  style={{
                    backgroundColor: '#338FEB',
                    width: 174,
                    height: '48px',
                  }}
                  onClick={() => {
                    setOpen(true)
                  }}
                >
                  <div className='d-flex align-items-center justify-content-center f-medium_20 '>
                    <img
                      className='button-add-circle-fill'
                      style={{ width: '20px', height: 'auto' }}
                      src={'/images/button-icon/add-circle-fill.svg'}
                      alt={'Add'}
                    />{' '}
                    <span className=' text-light'>Add Owner(s)</span>
                  </div>
                </Button>
              )}
            </div>
            <Table>
              <TableHead>
                <TableRow className='default-black-color'>
                  <TableCell>No.</TableCell>
                  <TableCell>Customer Names</TableCell>
                  <TableCell>Email for Nofication</TableCell>
                  <TableCell>Number mobile</TableCell>
                  <TableCell>Ownership</TableCell>
                  <TableCell align='right' className='pr-16 w-150'>
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.length ? (
                  rows.map((row, key) => (
                    <TableRow key={`order-${row.id}-${key}`}>
                      <TableCell>{key + 1}</TableCell>
                      <TableCell>{row.name}</TableCell>
                      <TableCell>{row.email}</TableCell>
                      <TableCell>{row.mobile_number}</TableCell>
                      <TableCell>
                        {row.type === 'co-owner'
                          ? 'Co-Owner'
                          : row.type === 'main-owner'
                          ? 'Main Owner'
                          : row.type}
                      </TableCell>
                      <TableCell align='right' className='pr-16 w-150'>
                        <span
                          className='hover-span-action'
                          data-toggle='tooltip'
                          data-placement='top'
                          title='Edit'
                          onClick={() => {
                            setOpen(true)
                            setEditData(row)
                          }}
                        >
                          <img
                            style={{ width: '20px', height: 'auto' }}
                            src={'/images/button-icon/edit.svg'}
                            alt={'Edit'}
                          />
                        </span>
                        {/* <span
                          className={`hover-span-action-remove `}
                          onClick={() => {
                            setRemove(true)
                            setEditData(row)
                          }}
                          data-toggle='tooltip'
                          data-placement='top'
                          title='Delete Admin'
                        >
                          {' | '}
                          <img
                            style={{ width: '20px', height: 'auto' }}
                            src={'/images/button-icon/delete.svg'}
                            alt={'Remove'}
                          />
                        </span> */}
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <></>
                )}
              </TableBody>
            </Table>
            {totalRows > 10 && (
              <TablePagination
                className='table-pagination-customer'
                labelRowsPerPage={`Row per page`}
                labelDisplayedRows={({ from, to, count }) => {
                  return `${from}-${to} of ${count}`
                }}
                rowsPerPageOptions={[10, 20, 50]}
                component='div'
                count={totalRows}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            )}

            <Form.Item className='d-flex align-items-center justify-content-center mt-32 mb-32'>
              <Button
                variant='primary'
                type='primary'
                htmlType='submit'
                className='lms-btn btn btn-bold btn-icon-h text-light'
                style={{
                  backgroundColor: '#338FEB',
                  width: 200,
                  height: '40px',
                }}
              >
                <div className='d-flex align-items-center justify-content-center f-medium_20 text-light'>
                  Save
                </div>
              </Button>
            </Form.Item>
          </Form>

          <Modal
            className='lms-modal lms-modal-noti'
            title={
              <div className='f-semibold_30'>
                {editData ? 'Edit Owner' : 'Add Owner'}
              </div>
            }
            closeIcon={
              <svg
                width='20'
                height='20'
                viewBox='0 0 20 20'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <g clip-path='url(#clip0_2986_20742)'>
                  <rect x='5' y='5' width='10' height='10' fill='white' />
                  <path
                    d='M9.47097 9.35216L10.0013 9.88249L10.5316 9.35215L12.3583 7.52546L12.4761 7.6433L10.6495 9.47L10.1191 10.0003L10.6495 10.5307L12.4761 12.3573L12.3583 12.4752L10.5316 10.6485L10.0013 10.1182L9.47098 10.6485L7.64428 12.4752L7.52643 12.3573L9.35313 10.5307L9.88347 10.0003L9.35313 9.47L7.52643 7.6433L7.64428 7.52545L9.47097 9.35216ZM0.917969 10.0003C0.917969 15.0169 4.98471 19.0837 10.0013 19.0837C15.0178 19.0837 19.0846 15.0169 19.0846 10.0003C19.0846 4.98373 15.0178 0.916992 10.0013 0.916992C4.98471 0.916992 0.917969 4.98374 0.917969 10.0003Z'
                    fill='#768394'
                    stroke='white'
                    stroke-width='1.5'
                  />
                </g>
                <defs>
                  <clipPath id='clip0_2986_20742'>
                    <rect width='20' height='20' fill='white' />
                  </clipPath>
                </defs>
              </svg>
            }
            open={open}
            onCancel={() => {
              setOpen(false)
              setEditData(null)
            }}
            footer={null}
            width='560px'
          >
            <AddOwnerComponent
              values={editData}
              companyId={id}
              handleCancel={() => {
                setOpen(false)
                setEditData(null)
              }}
              handleSave={(value) => {
                getAllOwners()
                setOpen(false)
                setEditData(null)
              }}
            />
          </Modal>
        </Card.Body>
      </Card>
    </>
  )
}
