/* eslint-disable no-restricted-imports */
import React, { useState, useEffect } from 'react'
import 'react-phone-number-input/style.css'
import OverviewItemComponent from './overview/OverviewItemComponent'
import makeRequest from '../../../libs/request'
import { showErrorMessage } from '../../../actions/notification'

export default function HistoryComponent(props) {
  const { value = {}, show = false, tab } = props
  const [certificationList, setCertificationList] = useState([])
  const getCompanyInfo = async () => {
    makeRequest('get', `icpa/certifications/company/${value?._id}/get-all`, {
      status: 'archived',
    })
      .then(({ data }) => {
        if (data.signal) {
          let itemList = data.data
          if (itemList?.length > 0) {
            setCertificationList(itemList)
          } else {
            setCertificationList([])
          }
        } else {
          return showErrorMessage(data.message)
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  useEffect(() => {
    if (value?._id && show) {
      getCompanyInfo()
    }
  }, [value, show, tab])
  return (
    <>
      {certificationList.map((i, index) => (
        <div key={index} className='mt-24'>
          <OverviewItemComponent value={i} />
        </div>
      ))}
    </>
  )
}
