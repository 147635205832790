import React, { useEffect, useState } from "react";
import ApexCharts from "react-apexcharts";

export const REMINDER_STATUS = {
	SNOOZED: "1",
	MISSED: "2",
	ACKNOWLEDGED: "3",
	REMOVED: "deleted",
};

const CurrentYearComponent = ({ selectData }) => {
	const [series, setSeries] = useState([]);
	const [labels, setLabels] = useState([]);

	const getValueReminders = (reminders = []) => {
		let labelsList = [];
		let seriesList = [];
		reminders.map((item) => {
			labelsList.push(item?.name ?? "");
			seriesList.push(item?.status === REMINDER_STATUS?.SNOOZED ? 50 : 100);
		});
		setLabels(labelsList);
		setSeries(seriesList);
	};

	useEffect(() => {
		if (selectData) {
			getValueReminders(selectData?.reminders);
		}
	}, [selectData]);
	let state2 = {
		series: series,
		options: {
			labels: labels,
			chart: {
				type: "radialBar",
				height: 450,
			},
			colors: [
				"#FF443C",
				"#FF8800",
				"#FFCA41",
				"#A1DC67",
				"#02C038",
				"#29B9E6",
				"#338FEB",
				"#003AA5",
				"#9747FF",
				"#FF0492",
			],
			responsive: [
				{
					breakpoint: 480,
					options: {
						legend: {
							position: "bottom",
							offsetX: 0,
							offsetY: 0,
						},
					},
				},
			],
			plotOptions: {
				radialBar: {
					borderRadius: 30,
					dataLabels: {
						show: false,
					},
				},
			},
			legend: {
				show: true,
				position: "bottom",
				horizontalAlign: "left",
				markers: { shape: "circle" },
				fontSize: "14px",
				fontWeight: 400,
				labels: {
					colors: "#1F263E",
					useSeriesColors: false,
				},
			},
			stroke: {
				lineCap: "round",
			},
		},
	};
	return (
		<>
			<div
				style={{
					position: "absolute",
					top: "-52px",
					right: "0px",
				}}
				className="rowy-center-end"
			>
				<div
					style={{
						background: "#F4F7FE",
						border: "1px solid #1F263E",
						padding: "6px 12px",
						borderRadius: "6px",
					}}
					className="f-medium_20"
				>
					(Y1) {selectData?.valid_from} - {selectData?.valid_to}
				</div>
			</div>
			<div className="apex-charts-row">
				<ApexCharts options={state2.options} series={state2.series} type="radialBar" height={450} />
			</div>
		</>
	);
};

export default CurrentYearComponent;
